 
const member = {
    namespaced: true,
    state: {
       dialog:false,
    }, 
    // 逻辑处理,同步函数
    mutations: {
      OPEN_MEMBERDIALOG(state) {
        state.dialog = true;
      },
      CLOSE_MEMBERDIALOG(state) {
        state.dialog = false
      },
    },
    // 逻辑处理,异步函数
    actions :{
        openMemberDialog({ commit }) {
            commit('OPEN_MEMBERDIALOG');
          },
 
    }
}
export default member;