import { getToken } from '@/utils/auto'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
 
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
Vue.use(VueRouter)
const mainRouters = (prop , mode)=>[
    {
      path: 'swiper',
      name: prop + '.swiper',
      component: ()=> import('@/views/swiper/index.vue'),
    },
    {
      path: 'silhouette-lite',
      name: prop + '.silhouette-lite',
      component: ()=> import('@/views/silhouette_lite/index.vue'),
      // children: silhouetteLiteRouters('silhouette')
    },
    {
      path: 'square',
      name:  prop + '.square',
      component: ()=> import('@/views/square/index.vue'),
      // children: squareRouters('square')
    },
    // {
    //   path: 'ads',
    //   name:  prop + '.ads',
    //   component: ()=> import('@/views/ads_put_in/index.vue'),
    //   // children: adsPutInRouter('ads')
    // },
    {
      path: 'about',
      name:  prop + '.about',
      component: ()=> import('@/views/about_me/index.vue'),
      // children: aboutMeRouters('about')
    },
    {
      path: 'swiper-upload-model',
      name:  prop + '.upload-model',
      component: ()=> import('@/components/footer/components/uploadModel.vue'),
      meta:{isMember : true , isBindPhone : true}
    },
    {
      path: 'swiper-download-app',
      name:  prop + '.download-app',
      component: ()=> import('@/components/footer/components/downloadApp.vue'),
    },
    {
      //swiper-user-agreement
     // prop +'.user-agreement'
      path: 'shizhan-agreement',
      name: 'shizhan-agreement' ,
      component: ()=> import('@/components/footer/components/userAgreement.vue'),
    },
    // {
    //     // swiper-privacy-agreement
    //   //  prop +  '.privacy-agreement'
    
      
    //   path: 'shizhan-agreement',
    //   name: 'shizhan-agreement',
    //   component: ()=> import('@/components/footer/components/privacyAgreement.vue'),
    // },
    {
      // path: swiper-technical-support
      // name:  prop + '.technical-support',
      path: 'support',
      name: 'support',
      component: ()=> import('@/components/footer/components/technicalSupport.vue'),
    },
    {
      path: 'swiper-set-user',
      name:  prop + '.swiper-set-user',
      component: ()=> import('@/views/set_user_info/index.vue'),
    },
    {
      path: '/swiper-my-material',
      name:  prop + '.swiper-my-material',
      component: ()=> import('@/views/my_material/index.vue'),
      meta:{isLogin : true}
    },    
] 


// const silhouetteLiteRouters = (prop , mode)=>[
//   {
//     path: 'silhouette',
//     name: prop + '.silhouette',
//     component: ()=> import('@/views/silhouette/index.vue')
//   },
// ] 
// const squareRouters = (prop , mode)=>[
//   {
//     path: 'square',
//     name: prop + '.square',
//     component: ()=> import('@/views/square/index.vue')
//   },
// ]
// const adsPutInRouter = (prop , mode)=>[
//   {
//     path: 'ads',
//     name: prop + '.ads',
//     component: ()=> import('@/views/ads_put_in/index.vue')
//   },
// ]
// const aboutMeRouters = (prop , mode)=>[
//   {
//     path: 'ads',
//     name: prop + '.ads',
//     component: ()=> import('@/views/ads_put_in/index.vue')
//   },
// ]
// {
//   path: '/',
//   redirect:'/swiper',
// },

const routes = [
    {
      path: '/',
      name: 'Main',
      redirect:'/swiper',
      component: ()=> import('@/views/main.vue'),
      children: mainRouters('main')
    },
    {
      path: '/404',
      name: '.404',
      component: ()=> import('@/views/404.vue'),
    },
]




const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  // linkActiveClass:'router_active',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

function gain404Page(str , array){
   for(let i = 0 ; i < array.length ; i++){
    if(array[i]['path'] === str) {
      return true
    }
   }
   return false;
}

router.beforeEach((to,from,next) =>{
  // document.title = '时绽空间';
  let is404 = gain404Page(to['path'] , router.getRoutes());
  if(!is404){  next('/404');  }
  else {
    if(to.meta['isLogin'] || to.meta['isMember'] || to.meta['isBindPhone']){
      if(store.getters.userInfo === null){
       store.dispatch('snackbar/openSnackbar',{ msg:'请先登录', color:'warning'});
       return;
      }
      if(to.meta['isMember'] && store.getters.userInfo['silhouette_status'] !== 1){
        store.dispatch('snackbar/openSnackbar',{ msg:'请先在app开通会员', color:'warning'});
        return;
      }
      // if(to.meta['isBindPhone'] && store.getters.userInfo['phone'] === "15727699875"){
      //   store.dispatch('member/openMemberDialog');
      //   return;
      // }
    }
      next(); 
  }
  // console.log(to['path']);
  // console.log( router.getRoutes());
  // console.log(DownloadApp['methods']['open']);
  // DownloadApp['methods']['open']().then(()=>{
  //   console.log(111);
  //   next();
  // });
  // DownloadApp['methods']['open']();
 
  // if(to.meta['isLogin']){
  //   if(store.getters.userInfo === null){
  //    store.dispatch('snackbar/openSnackbar',{ msg:'请先登录', color:'warning'})
  //   }else {
  //     next();
  //   }
  // }else if(to.meta['isMember']){
  //   if(store.getters.userInfo === null){
  //     store.dispatch('snackbar/openSnackbar',{ msg:'请先登录', color:'warning'})
  //   }else {
  //     if(store.getters.userInfo['silhouette_status'] === 1) {
  //       next();
  //     }else {
  //       // info
  //       next();
  //       // store.dispatch('snackbar/openSnackbar',{ msg:'请先在app开通会员', color:'warning'})
  //     }
  //   }
  // }else {
  //   if(to.name === null || to.name === undefined){
  //     next('/');
  //   }else{
  //     next();
  //   }
   
  // }
})



export default router
