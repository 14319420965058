<template>
    <v-app id="shizhan_main" app>
    <AppBar></AppBar>
    <v-main>
         <v-container fluid class="pa-0" >
           <router-view></router-view>
         </v-container>
    </v-main>
   
   <Footer></Footer>
  </v-app>

</template>

<script>
import Footer from "@/components/layout/footer.vue"
import AppBar from "@/components/layout/appBar.vue"

export default {
  name: 'App',
  components:{
    AppBar,
    Footer,
  }
};
</script>
<style scoped>
#shizhan_main {
  background-color: #272727 !important;
}
</style>
