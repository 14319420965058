import axios from 'axios'
import store from '@/store'
import { getToken } from './auto'
import  router from '@/router/index'
const service = axios.create({
  // baseURL: process.env.VUE_APP_URL,
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
      if(getToken() === undefined) config.auth = undefined;
       return config
  },
  error => {
    store.dispatch('snackbar/openSnackbar',{ msg:'请求发生错误 未发送请求', color:'error'})
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if(response.status === 200) {
      const {data} = response;
      if(data['error_code'] === 0) {
        return data
      }
      else if(data['error_code'] === -1006){
        //  重新登录的时候未把后端返回的上个设备id 返回 也能重新登录成功
        store.dispatch('user/remoteLogin' , data['data']).then(res=>{
          store.dispatch('snackbar/openSnackbar',{ msg:data['msg'], color:'error'})
          router.replace('/swiper');
        })
      }else{
        store.dispatch('snackbar/openSnackbar',{ msg:data['msg'] || '发生异常', color:'error'})
      }
      
    }else{
      store.dispatch('snackbar/openSnackbar',{ msg:'请检查状态码', color:'error'})
    }
  },
  error => {
    store.dispatch('snackbar/openSnackbar',{ msg:error['message'] || '发生异常', color:'error'})
    //  进入一个新页面 ？？？？
    // console.log(error);
    // const err = error.response.data
    // console.log('err:' + JSON.stringify(error.response.data)) // for debug
    return Promise.reject(error);
  }
)
export default service
