import request from '@/utils/request'
// const api = '/api';
const api = '';
// 登录
export function login( data ){
    return request({
     url:`${api}/user/login/token`,
     method:'POST',
     data
    })
 }

// 获取用户信息
 export function getUserInfo(token){
    return request({
     url:`${api}/user/user/me`,
     method:'GET',
     auth: { username: token }
    })
 }

// 注册用户
export function registerUser(data){
   return request({
    url:`${api}/user/user/register`,
    method:'POST',
    data
   })
}
//发送短信-用于用户注册
export function getCodeState(data){
   return request({
    url:`${api}/user/user/phonecode-for-register`,
    method:'POST',
    data
   })

}
//发送短信-用于忘记密码
export function getCodeForgetPassword(data){
   return request({
    url:`${api}/user/user/phonecode-for-forget-password`,
    method:'POST',
    data
   })
}

// 确定忘记密码修改密码
export function confirmChangeForgetPassword(data){
   return request({
    url:`${api}/user/user/set-password-by-phone-code`,
    method:'POST',
    data
   })
}

 // 获取隐私协议 
export function getPrivacyPolicyshizhan (key) {
   return request({
     url: `${api}/system/system-config/fetch-bykey?key=${key}`,
     method: 'GET'
   })
 }