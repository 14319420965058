
import { login, getUserInfo } from '@/api/user'
import { uuid } from 'vue-uuid'
import { getToken, setToken ,removeToken } from '@/utils/auto'
const state = {
    redis_logined_device_id : undefined,
    login_device_id : undefined,
    userInfo : null,
    // token : undefined,
}

const  mutations = {
    SET_USER_NICKNAME:(state , nickname)=>{
      if(state.userInfo !== null) {
        state.userInfo['nickname'] = nickname;
      }
    },
    SET_REDIS_LOGIN_DEVICE_ID: (state , str) => {
        state.redis_logined_device_id = str;
    },
    SET_LOGIN_DEVICE_ID: (state, str) => {
        state.login_device_id = str;
    },
    SET_USERINFO: (state , map) => {
        state.userInfo = map;
    },
    SET_LOGIN_OUT: (state , empty ) => {
        state.userInfo = empty;
        removeToken();
    },
    // SET_TOKEN: (state , map) => {
    //     state.token = map;
    // },
}

const actions = {
    login( {commit} , loginParam) {
        return new Promise((resolve , reject) => {
            if(state.login_device_id === undefined) commit('SET_LOGIN_DEVICE_ID', uuid.v4());
            if(state.redis_logined_device_id != undefined) loginParam['redis_logined_device_id'] = state.redis_logined_device_id;
            //  state.login_device_id
            loginParam['login_device_id'] = uuid.v4();
            login(loginParam).then(response=>{ 
                setToken(response['data']['token']);
                resolve(response);
            }).catch(error=> {
                reject(error);
            })
        })
    },
    getUserInfo( {commit} , token ){
        return new Promise((resolve , reject) => {
            getUserInfo(token).then(response => {
                commit('SET_USERINFO' ,response['data'] );
                resolve(response);
            }).catch(error =>{
                reject(error)
            })
        });
    },
    remoteLogin({commit}, redisID){
        return new Promise((resolve , reject)=>{
            if(redisID === null || redisID === undefined) {
                reject('redisID为空');
            }else {
                commit('SET_REDIS_LOGIN_DEVICE_ID', redisID);
                commit('SET_LOGIN_OUT' , null);
                resolve();
            }
           
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }