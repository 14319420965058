const getters = {
    userInfo: state => state.user.userInfo,
    //message 
    // text: state => state.message.text,
    // show: state => state.message.show,
    // color: state => state.message.color,
    // closeBtn: state => state.message.closeBtn,
    // closeBtnType: state => state.message.closeBtnType,
    // timeout: state => state.message.timeout,
    // callback: state => state.message.callback,
    // token: state => state.user.token,
}
export default getters