import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters,
   /* vuex数据持久化配置 */
   plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      // 存储的 key 的key值
      key: "store",
       // 只持久化存储user模块的状态
        paths: ['user' , ]  //'snackbar'
      // reducer(state) { //render错误修改
      //   // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
      //   return { ...state };  
      // }
    })
  ]
})

export default store

