import store from '@/store'
// const ePattern = /^([A-Za-z0-9_-.])+@([A-Za-z0-9_-.])+.([A-Za-z]{2,4})$/; //Email
const mPattern = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
// /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/; 
const urlP = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

export class Verification {
        // constructor() {} 构造函数

        // 验证手机号码
        rulePhone = (phone)=>{
        if(phone === '' || phone === undefined || phone === null ){
            store.dispatch('snackbar/openSnackbar',{msg:'请输入手机号码',color:'warning' });
            return false
        }else {
            if(mPattern.test(phone)){
               return true;
            }else {
                store.dispatch('snackbar/openSnackbar',{msg:'请检查输入手机号码',color:'warning' });
                return false
            }
        }
        };
        
        // 验证验证码
        ruleCode = (code)=>{
            if(code === '' || code === undefined || code === null ) {
                store.dispatch('snackbar/openSnackbar',{msg:'请输入验证码',color:'warning' });
                return false
            }else{
               if(code.length === 6) {
                 return true;
               }else {
                store.dispatch('snackbar/openSnackbar',{msg:'请检查输入验证码',color:'warning' });
                return false;
               }
            }
        }

        // 验证昵称
        ruleNickname = (nickname)=>{
            if(nickname === '' || nickname === undefined || nickname === null ) {
                store.dispatch('snackbar/openSnackbar',{msg:'请输入昵称',color:'warning' });
                return false;
            }else{ 
                 if(nickname.length < 2 || nickname.length > 10){
                    store.dispatch('snackbar/openSnackbar',{msg:'昵称长度2-10之间',color:'warning' });
                    return false;
                 }
                 return true;
            }
        }

        // 验证密码
        rulePassword = (password)=>{
            if(password === '' || password === undefined || password === null ){
                store.dispatch('snackbar/openSnackbar',{msg:'请输入密码',color:'warning' });
                return false
            }
            return true;
        };

         /// 注册验证密码  不跟登录写在一起
         ruleRegisterPassword = ( secret , confirmSecret )=>{
            if(secret === '' || secret === undefined || secret === null ){
                store.dispatch('snackbar/openSnackbar',{msg:'请输入密码',color:'warning' });
                return false
            }
            else if(confirmSecret === '' || confirmSecret === undefined || confirmSecret === null ){
                store.dispatch('snackbar/openSnackbar',{msg:'请输入确定密码',color:'warning' });
                return false
            }
            else {
                if(secret.length < 6 || secret.length > 12) {
                    store.dispatch('snackbar/openSnackbar',{msg:'密码长度6-12之间',color:'warning' });
                    return false;
                }else {
                    // if( confirmSecret )
                    if(secret === confirmSecret){
                        return true;
                    }else {
                        store.dispatch('snackbar/openSnackbar',{msg:'两次密码输入不一致',color:'warning' });
                        return false; 
                    }
                }
            }
         };

        ruleUrl = (url) => {
            console.log(urlP.test(url));
        }
}
   