<template>
    <!-- prominent color="#000"  :dense="currentBreakpoint['xs']" outlined height="60"-->
    <v-app-bar app dark elevation="9" >
        <!--  justify-md="space-around":justify="colsOne ? 'center':'space-between'"  :justify="colsOne ? 'center':'space-between'" -->
        <v-row class="d-flex align-center" v-if="currentBreakpoint['xs']">
            <!-- xs 设备下的菜单 -->
           <div class="text-center" >
             <v-menu rounded="0" eager offset-y  bottom transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                 <v-btn  small dark v-bind="attrs" v-on="on" color="dark">
                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                 </v-btn>
                </template>
                <v-list :dense="currentBreakpoint['xs']" rounded>
                 <v-list-item v-for="(item, index) in navTabList" :key="index"  @click="changeRouter(item.path)">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                 </v-list-item>
                </v-list>
             </v-menu>
           </div>
           <!-- :aspect-ratio="2/2" -->
           <!-- logo max-height="40" --> 
           <v-img class="ml-3 "  contain max-width="40"   src="@/assets/images/index/shizhan_max_log.png" @click="jumpHome"></v-img>
           <div class="flex_one  pr-4" >
            <!-- <div v-if="userInfo !== null" class="flex_one" >
                <v-menu nudge-left="66" nudge-bottom="36" rounded="0" eager   transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                 <v-btn  small text v-bind="attrs" v-on="on" color="transparent">
                    <v-badge avatar overlap color="#b3b3b3"> 
                <template v-slot:badge>
                  <v-avatar>
                    <v-img src="@/assets/images/index/icon_set_dark.png"></v-img>
                  </v-avatar>
               </template>
               <v-avatar size="38">
                 <v-img :src="ossUrl + userInfo['avatar_url']"></v-img>
               </v-avatar>
             </v-badge>
                 </v-btn>
                </template>
                <v-list :dense="currentBreakpoint['xs']" rounded class="px-0">
                 <v-list-item v-for="(item, index) in setList" :key="index"  @click="goSetUserRouter(index)">
                           <svg-icon v-if="index === 0" type="mdi" size="18"   :path="iconPath.user_edit"></svg-icon>
                        <svg-icon v-else type="mdi" size="18"   :path="iconPath.question"></svg-icon>
                    <v-list-item-title class="text-sm-body-2">{{ item.title }}</v-list-item-title>
                 </v-list-item>
                </v-list>
                <v-btn small block color="#f96363" @click="loginOut"> 
                     退出登录
                     <svg-icon type="mdi" size="18"  class="ml-2" :path="iconPath.logOutIcon"></svg-icon>
                 </v-btn>
                </v-menu>
            </div> -->

            <!-- <div v-else>
                <v-btn  elevation="3" rounded color="#65c730" small @click="isLogin = true"> 登 录 </v-btn>
                <v-btn elevation="3" rounded color="#ffc419" small class="ml-2" @click="isRegister = true"> 注 册 </v-btn>
            </div> -->
           </div>
        </v-row>
         <!-- xs 之外的布局 -->
         <v-row v-else>
            <v-col class="overflow-auto - d-flex justify-space-around" no-gutters>
                    <v-container  class="hidden-sm-and-down">
                        <v-img contain max-height="20"  max-width="80" src="@/assets/images/index/shizhan_max_log.png" @click="jumpHome"></v-img>
                    </v-container>
             </v-col>
            <v-col  :class="cols">
             <div class="d-flex justify-center align-center " >
                <v-btn   @click="changeRouter(item.path)" class="d-flex mr-3  justify-center align-center "   color="transparent"  v-for="item in navTabList" :key="item['path']">
                   <div class="mr-1">
                    <v-img contain :src="$route.path.includes(item.path) || $route.path.includes(item.agreement) || $route.path.includes(item.support)? item['active_icon'] : item['icon']"></v-img>
                   </div>
                   <div  :class="{ 'overline': $vuetify.breakpoint.smAndDown, 'caption': $vuetify.breakpoint.md, 'subtitle-1': $vuetify.breakpoint.lgAndUp , 'active_text': $route.path.includes(item.path)  || $route.path.includes(item.agreement) || $route.path.includes(item.support)}">   {{ item['title'] }}</div>
                </v-btn>
             </div>
             </v-col>
       
            <v-col  class="hidden-sm-and-down d-flex justify-end align-center title">
              <!-- <div v-if="userInfo !== null" class="flex_one" >
                <v-menu nudge-left="66" nudge-bottom="36" rounded="0" eager   transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                 <v-btn  small text v-bind="attrs" v-on="on" color="transparent">
                    <v-badge avatar overlap color="#b3b3b3"> 
                <template v-slot:badge>
                  <v-avatar>
                         <v-img src="@/assets/images/index/icon_set_dark.png"></v-img>
                  </v-avatar>
               </template>
               <v-avatar size="38">
                 <v-img :src="ossUrl + userInfo['avatar_url']"></v-img>
               </v-avatar>
             </v-badge>
                 </v-btn>
                </template>
                <v-list :dense="currentBreakpoint['xs']" rounded class="px-0">
                 <v-list-item v-for="(item, index) in setList"  :key="index" @click="goSetUserRouter(index)">
                        <svg-icon v-if="index === 0" type="mdi" size="20"  class="mr-1" :path="iconPath.user_edit"></svg-icon>
                        <svg-icon v-else type="mdi" size="20"  class="mr-1" :path="iconPath.question"></svg-icon>
                    <v-list-item-title  class="text-sm-body-2">{{ item.title }}</v-list-item-title>
                 </v-list-item>
                </v-list>
                <v-btn small block color="#f96363" @click="loginOut"> 
                     退出登录
                     <svg-icon type="mdi" size="18"  class="ml-2" :path="iconPath.logOutIcon"></svg-icon>
                 </v-btn>
                </v-menu>
              </div> -->
              
              <!-- <div v-else>
                <v-btn elevation="3" rounded color="#65c730" small class="hidden-sm-and-down mr-4 px-5 login" @click="isLogin = true"> 登 录 </v-btn>
                 <v-btn elevation="3" rounded color="#ffc419" small class="hidden-sm-and-down px-5 register" @click="isRegister = true"> 注 册 </v-btn>
              </div> -->
                  
             </v-col>

         </v-row>


        <!-- 登录弹窗 -->
        <!-- 登录弹窗 -->
        <!-- 登录弹窗 -->
        <template>
                <v-dialog  v-model="isLogin" width="430" >
                    <v-sheet>
                        <v-row no-gutters>
                               <v-col class=" d-flex justify-center col_logo_container" >
                                <v-img contain max-width="60" max-height="60"
                                src="@/assets/images/index/logo_black.png"></v-img>
                                 <!-- <div class="icon_close"> -->
                                    <v-icon @click="isLogin = false" class="icon_close">mdi-close</v-icon>
                                 <!-- </div> -->
                            </v-col>
                            <!-- <v-col class=" d-flex justify-center">
                                <v-img contain max-width="60" max-height="60"
                                    src="@/assets/images/index/logo_black.png"></v-img>
                            </v-col> -->
                            <v-col class="px-10" cols="12">
                                <!-- label="Phone"  -->
                                <v-form ref="form_login" lazy-validation>
                                    <v-text-field  color="#6e6e6e"  v-model="loginParam['account']" placeholder="请输入手机号" clear-icon="mdi-close-circle"
                                        clearable filled rounded dense></v-text-field>
                                        <!--  label="Password"  -->
                                    <v-text-field
                                        v-model="loginParam['secret']"
                                        :append-icon="isLoginPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="isLoginPassword ? 'text' : 'password'"
                                        color="#6e6e6e"
                                        @click:append="isLoginPassword = !isLoginPassword"
                                        clear-icon="mdi-close-circle"  clearable
                                        placeholder="请输入密码" filled rounded dense>
                                        </v-text-field>
                                </v-form>
                            </v-col>
                            <v-col class="px-10" cols="12">
                                <v-btn  block color="#65c62f" height="35" large rounded class="white--text" @click="login" :loading="loginLoading" :disabled="loginLoading">登录</v-btn>
                            </v-col>
                            <v-col class="d-flex justify-center pt-2 pb-5 " :class="{ 'overline': $vuetify.breakpoint.smAndDown, 'caption': $vuetify.breakpoint.md, 'subtitle-1': $vuetify.breakpoint.lgAndUp }">
                                <v-sheet class="forget_password primary--text user-select"  @click="forgetPassword">忘记密码</v-sheet>
                                <p class="mr-2 ml-2 user-select">|</p>
                                <v-sheet class="primary--text user-select" @click="registerAccount">注册账号</v-sheet>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-dialog>
        </template>

          <!-- 注册弹窗 -->
          <!-- 注册弹窗 -->
          <!-- 注册弹窗 -->
        <template>
                <v-dialog v-model="isRegister" persistent width="430" >
                    <v-sheet>
                        <v-row no-gutters>
                            <v-col class=" d-flex justify-center col_logo_container" >
                                <v-img contain max-width="60" max-height="60"
                                src="@/assets/images/index/logo_black.png"></v-img>
                                    <v-icon @click="isRegister = false" class="icon_close">mdi-close</v-icon>
                            </v-col>
                            <v-col class="px-10" cols="12">
                                <v-form ref="form_login" lazy-validation >
                                    <v-text-field placeholder="请输入手机号" clear-icon="mdi-close-circle"
                                        v-model="registerParam['account']"
                                        color="#6e6e6e"
                                        clearable filled rounded dense></v-text-field>
                                        <v-row  class="px-2">                                         
                                          <v-col class="px-0" cols="12" >
                                            <div class="px-0 code_container">
                                                <v-text-field 
                                                color="#6e6e6e"
                                                hide-details="auto"
                                                background-color="transparent"
                                                placeholder="请填写验证码" v-model="registerParam['phonecode']" filled rounded dense ></v-text-field>
                                                <v-sheet @click="getCode" color="transparent" 
                                                 class="pr-4 gain_code_container" 
                                                 :class="{ 'overline': $vuetify.breakpoint.smAndDown, 'caption': $vuetify.breakpoint.md, 'subtitle-1': $vuetify.breakpoint.lgAndUp }">
                                                  <div v-if="showCodeTime" class="green--text mr-4">
                                                       {{codeTime}}s
                                                   </div>
                                                   <div v-else class="primary--text" >
                                                       获取验证码
                                                   </div>
                                             </v-sheet> 
                                            </div>
                                          </v-col>
                                        </v-row>
                                        <v-text-field color="#6e6e6e" placeholder="请填写昵称" v-model="registerParam['nickname']" filled rounded dense> </v-text-field>
                                        <!--  label="Password"  -->
                                    <v-text-field 
                                        color="#6e6e6e"
                                        v-model="registerParam['secret']"
                                        :append-icon="isRegisterPasswordOne ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="isRegisterPasswordOne ? 'text' : 'password'"
                                        @click:append="isRegisterPasswordOne = !isRegisterPasswordOne"
                                        clear-icon="mdi-close-circle"  clearable
                                        placeholder="请填写密码" filled rounded dense>
                                        </v-text-field>

                                        <v-text-field 
                                        v-model="registerParam['confirm_secret']"
                                        color="#6e6e6e"
                                        :append-icon="isRegisterPasswordTwo ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="isRegisterPasswordTwo ? 'text' : 'password'"
                                        @click:append="isRegisterPasswordTwo = !isRegisterPasswordTwo"
                                        clear-icon="mdi-close-circle"  clearable
                                        placeholder="请再次填写密码" filled rounded dense>
                                        </v-text-field>
                                </v-form>
                            </v-col>
                            <v-col class="px-10 pb-10" cols="12">
                                <v-btn  block color="#65c62f" height="35" large rounded class="white--text" @click="register">注册</v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-dialog>
        </template>
         
        <template>
            <v-dialog
            v-model="forgetDialog"
            max-width="400"
            transition="dialog-bottom-transition">
            <v-card class="px-4">
               <div class="d-flex justify-space-between py-4">
                 <!-- 空容器 用来布局 -->
                 <div></div> 
                 <div>忘记密码</div>
                 <div> <v-icon @click="forgetDialog = false" class="icon_close"> mdi-close </v-icon> </div>
               </div>
               <v-text-field 
                  name="randomName123456" 
                  autocomplete="nope"
                  placeholder="请输入手机号码"
                  v-model="passwordMap['phone']"
                  hide-details="auto"
                  clear-icon="mdi-close-circle"
                  class="mb-4"
                  color="#6e6e6e"
                  clearable filled rounded dense></v-text-field>
                 
                <div class="px-0 code_container" >
                    <v-text-field
                      name="randomName1234567"
                      autocomplete="nope"
                      placeholder="请输入验证码"
                      v-model="passwordMap['code']"
                      background-color="transparent"
                      hide-details="auto"  
                      maxlength="6"        
                      filled rounded dense></v-text-field>
                    
                        <v-sheet color="transparent" 
                            class="pr-4 gain_code_container" 
                           :class="{ 'overline': $vuetify.breakpoint.smAndDown, 'caption': $vuetify.breakpoint.md, 'subtitle-1': $vuetify.breakpoint.lgAndUp }">
                           <span v-if="showForgetPasswordCodeTime" class="green--text mr-4">
                                {{forgetPasswordCodeTime}}s
                            </span>
                            <span v-else @click="getForgetPasswordCode" class="primary--text">
                                获取验证码
                            </span>
                        </v-sheet>
                  </div>
                  <v-text-field 
                  name="randomName1234" autocomplete="nope"
                                color="#6e6e6e"
                                class="mb-4"
                                v-model="passwordMap['new_secret']"
                                hide-details="auto"
                                clear-icon="mdi-close-circle" 
                                clearable
                               :append-icon="isNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                               :type="isNewPassword ? 'text' : 'password'"
                               @click:append="isNewPassword = !isNewPassword"
                                placeholder="请填写新密码" filled rounded dense></v-text-field>
                  <v-text-field 
                  name="randomName12345" autocomplete="nope"
                                hide-details="auto"
                                color="#6e6e6e"
                                v-model="passwordMap['confirm_secret']"
                                class="mb-4"
                                clear-icon="mdi-close-circle"  
                                clearable
                                :append-icon="isConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="isConfirmPassword ? 'text' : 'password'"
                                @click:append="isConfirmPassword = !isConfirmPassword"
                                placeholder="请再次填写密码" filled rounded dense></v-text-field>
          
                <v-row no-gutters class="px-4">
                  <v-col cols="6" class="mb-4">
                     <v-btn block depressed elevation="0" @click="forgetDialog = false" text plain rounded dense>
                      <span style="color:#64c62e">取消</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <!-- @click="confirmEditPassword" -->
                     <v-btn block depressed  rounded color="#64c62e"  dense @click="confirmBindForgetPassword">
                      <span class="white--text" >确定</span>
                    </v-btn>
                  </v-col>
               </v-row>
         </v-card>              
       </v-dialog>
        </template>
    </v-app-bar>
</template>
<script>

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiExport , mdiCogOutline , mdiHelpCircleOutline , mdiAccountEdit } from '@mdi/js';
import { mapGetters } from "vuex";
import * as tools from "@/utils/verification";
import { registerUser , getCodeState , getCodeForgetPassword , confirmChangeForgetPassword } from '@/api/user'
const vertical = new tools.Verification();
export default {
    name: 'AppBar',
    computed: {
       ...mapGetters(['userInfo']),
        currentBreakpoint(){
            const { lg, md, sm, xs } = this.$vuetify.breakpoint;
            let map = {};
            map['xs'] = xs ? true : false; // 判断当前是否是处于手机设备
            return map;
        },
        cols() {
            const { lg, md, sm, xs } = this.$vuetify.breakpoint;
            return sm ? [0, 12, 0] : xs ? [0, 12, 0] : [2, 6, 2];
        },
    },
    components: {
        SvgIcon
    },
    watch:{
     isLogin:function(newValue , oldValue) {
        if(!newValue){
            this.loginParam = {};
        }
     },
     isRegister:function(newValue , oldValue) {
        if(!newValue){
           this.registerParam = {};
           this.showCodeTime = false;
        }
     },
     forgetDialog:function(newValue , oldValue) {
        if(!newValue){
           this.passwordMap = {};
           this.showForgetPasswordCodeTime = false;
        }
     }
    },
    data: () => ({
       showForgetPasswordCodeTime:false,
       forgetPasswordCodeTime:60,
       forgetDialog:false,
       isNewPassword:false,
       isConfirmPassword:false,
       passwordMap:{
          'new_secret':'',
          'confirm_secret':'',
          'phone':'',
          'code' :''
       },

        loginLoading:false,
        codeTime:60,
        showCodeTime:false,
        ossUrl: process.env.VUE_APP_OSS_URL,
        loginParam:{
            secret:'',
            account:''
        },
        registerParam:{
            // account
            // confirm_secret
            // nickname
            // phonecode
            // secret
            // type 101
        },
        iconPath:{
             'logOutIcon': mdiExport,
             'user_set': mdiCogOutline,
             'question':mdiHelpCircleOutline,
             'user_edit':mdiAccountEdit
        },
        isLoginPassword:false,
        isRegisterPasswordOne:false,
        isRegisterPasswordTwo:false,
        isLogin: false,
        isRegister:false,
        navTabList: [
            {
                'icon': require('../../assets/images/index/silhouette.png'),
                'active_icon': require('../../assets/images/index/silhouette.active.png'),
                'title': '剪影立绘',
                'key': Symbol(),
                'link': {
                    'name': 'main.swiper'
                },
                'path': '/swiper',
                'agreement':'shizhan-agreement',
                'support' : 'support'
            },
            // {
            //     'icon': require('../../assets/images/index/silhouette_lite.png'),
            //     'active_icon': require('../../assets/images/index/silhouette_lite.active.png'),
            //     'title': '剪影立绘Lite',
            //     'key': Symbol(),
            //     'link': {
            //         'name': 'main.silhouette-lite'
            //     },
            //     'path': '/silhouette-lite'
            // },
            // {
            //     'icon': require('../../assets/images/index/square.png'),
            //     'active_icon': require('../../assets/images/index/square_active.png'),
            //     'title': '广场',
            //     'key': Symbol(),
            //     'link': {
            //         'name': 'main.square'
            //     },
            //     'path': '/square'
            // },
            // {
            //     'icon': require('../../assets/images/index/ads.png'),
            //     'active_icon': require('../../assets/images/index/ads.active.png'),
            //     'title': '广告投放',
            //     'key': Symbol(),
            //     'link': {
            //         'name': 'main.ads'
            //     },
            //     'path': '/ads'
            // },
            {
                'icon': require('../../assets/images/index/about.png'),
                'active_icon': require('../../assets/images/index/about_active.png'),
                'title': '关于我们',
                'key': Symbol(),
                'link': {
                    'name': 'main.about'
                },
                'path': '/about',
                'agreement': null,
                'support' : null
            },
        ],
        setList:[
            {
                'title':'账号与安全',
                'key':Symbol()
            },
            {
                'title':'建议与反馈',
                'key':Symbol()
            },
        ]
    }),
    methods: {
        confirmBindForgetPassword(){
            if(!vertical.ruleRegisterPassword(this.passwordMap['new_secret'] , this.passwordMap['confirm_secret'])) return;
            let obj = {
                'account': this.passwordMap['phone'],
                'phonecode': this.passwordMap['code'],
                'secret' : this.passwordMap['new_secret'],
                'confirm_secret' :  this.passwordMap['confirm_secret'],
            };
            confirmChangeForgetPassword(obj).then( result => {
                this.$store.dispatch('snackbar/openSnackbar',{ msg:result['data']['msg'] || '操作成功', color:'success'});
                this.forgetDialog = false;
            });
        },
        getForgetPasswordCode(){
          if(!vertical.rulePhone(this.passwordMap['phone'])) return;
          getCodeForgetPassword({ "type": 101 , "account": this.passwordMap['phone'] }).then(result => {
            this.$store.dispatch('snackbar/openSnackbar',{ msg:result['data']['msg'] || '操作成功', color:'success'})
            this.showForgetPasswordCodeTime = true;
            let t = setInterval(()=>{
                if(this.forgetPasswordCodeTime != 0 ){
              this.forgetPasswordCodeTime--;
            }
            else {
              this.showForgetPasswordCodeTime = false;
              this.forgetPasswordCodeTime = 60;
              clearInterval(t);
             }
            },1000)
          });
          
        },
        forgetPassword(){
            this.isLogin = false;
            this.forgetDialog = true;
        },
        jumpHome(){
            this.$router.replace('/swiper');
        },
        login(){
            if(!vertical.rulePhone(this.loginParam['account'])) return;
            if(!vertical.rulePassword(this.loginParam['secret'])) return;
            this.loginLoading = true;
            this.$store
                 .dispatch('user/login' , this.loginParam)
                 .then((res)=>{
                   let { data } = res;
                     this.$store
                     .dispatch('user/getUserInfo' , data.token).then(response=>{
                        this.isLogin = false;
                        this.loginLoading = false;
                        this.$store.dispatch('snackbar/openSnackbar',{ msg:'登录成功', color:'success'})
                     }).catch(error=>{
                         this.loginLoading = false;
                        console.log('获取用户信息失败');
                     });

            }).catch((err)=>{
                this.loginLoading = false;
                console.log('登录失败');
            });
        },
        getCode(){
            if(!vertical.rulePhone(this.registerParam['account'])) return;
            if(!this.showCodeTime){
              let map = {
                'account' : this.registerParam['account'],
                 "type":101
              } 
              getCodeState(map).then(res=>{
                 this.showCodeTime = !this.showCodeTime;
                 this.codeTime = 60;
                 const time = setInterval(()=>{
                    if(this.codeTime === 1){
                        this.showCodeTime = !this.showCodeTime;
                        clearInterval(time);
                    }
                    this.codeTime--;
                 },1000);
              });
            
            }else{
               this.$store.dispatch('snackbar/openSnackbar',{ msg:'请耐心等待', color:'warning'});
            }
        },
        register(){
            if(!vertical.rulePhone(this.registerParam['account'])) return;
            if(!vertical.ruleCode(this.registerParam['phonecode'])) return;
            if(!vertical.ruleNickname(this.registerParam['nickname'])) return;
            if(!vertical.ruleRegisterPassword(this.registerParam['secret'] , this.registerParam['confirm_secret'])) return;
            this.registerParam['type'] = 101;
            registerUser(this.registerParam).then(res=>{
               this.registerParam = {};
               this.isRegister = false;
               this.showCodeTime = false;
               this.isLogin = true;
               this.$store.dispatch('snackbar/openSnackbar',{ msg:'注册成功请登录', color:'success'});
            });
        },
        loginOut(){
           this.$store.commit('user/SET_LOGIN_OUT' , null);
           this.$store.dispatch('snackbar/openSnackbar',{ msg:'退出登录成功', color:'success'});
           this.$router.push('/');
        },
        registerAccount(){
        this.isLogin = false;
        this.isRegister = true;
        },
        changeRouter(path) {
            this.$router.push(path)  // 这种可以后退 和以下的方法选一即可
            if (this.$router.path === path) return  // 无痕浏览
        },
        goSetUserRouter(index){
        //    this.$store.dispatch('snackbar/openSnackbar',{ msg:'请前往App操作', color:'warning'});
          this.$router.push({
            name:'main.swiper-set-user',
            params:{
                'index':index
            }
          });
            // this.$router.push({
            //    path:'/swiper-set-user',
            //    query:{
            //     index
            //    }
            // }); 
            // console.log('跳转到user INFO 路由');
        }
    }
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {display:none}
:deep(.v-sheet) {
    border-radius: 15px;
   
}
.user-select {
    user-select:none;
}
.flex_one {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}
  
.col_logo_container {
      position: relative;
    .icon_close {
        position: absolute;
        right: 14px;
        top: 14px;
        // width: 40px;
        // height: 40px;
    }
}
.router-active {
    color: #65C62F !important;
}
.tab_item {
    background-color: #65C62F;
}
.active_text {
   color: #65C62F; 
}
.title {
    color: #fff;
}

.code_container {
    background-color: #f0f0f0 ;
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    .gain_code_container {
       min-width: 100px;
    }
}
// :v-deep .v-input__control .v-text-field__details {
//   display: none !important;
// }
</style>
