<template>
  <!--  transparent dark -->
  <!--  dark app fixed height="54"-->
  <v-footer app dark>
    <v-row class="overflow-auto"  :justify="cols ? 'center':'space-between'"  align="center" no-gutters >
      <v-col cols="auto" class="hidden-sm-and-down" @click="jumpHome">
        <v-img src="@/assets/images/index/shizhan_log.png"></v-img>
      </v-col>
      <v-col cols="auto" >
        <div class="d-flex" >
    <div  v-for="link in links" :key="link['name']" >
       <v-menu top  open-on-hover offset-y v-if="link['isMenu']">
           <template v-slot:activator="{ on, attrs }">
             <v-btn
              text
              color="white"
               rounded :class="{'overline': $vuetify.breakpoint.smAndDown, }"
               v-bind="attrs"
               v-on="on"
             >
             {{ link['name'] }}
             </v-btn>
           </template>
           <!-- 目前只有2个需要有菜单 所以不是用户协议即是隐私协议 -->
          <v-list  dense v-if="link['type'] == 'user_agreement'">
            <v-list-item 
              v-for="(item, index) in userAgreementList"
              :key="index"
              @click="toAgreementPage(item['param'])"
               >
               <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list  dense　v-else>
            <v-list-item 
              v-for="(item, index) in privacyAgreementList"
              :key="index"
              @click="toAgreementPage(item['param'])"
               >
               <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn v-else color="white"
            text
            @click="toggleRouter(link['path'])"
            rounded
            :class="{'overline': $vuetify.breakpoint.smAndDown, }">
            {{ link['name'] }}
        </v-btn>
        <!-- <v-btn
            v-else
            v-for="link in links"
            :class="{'overline': $vuetify.breakpoint.smAndDown, }"
            :key="link['path']"
            color="white"
            text
            rounded
            @click="toggleRouter(link['path'])"
             >
              {{ link['name'] }}
            </v-btn> -->
      
    </div>
         
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
  export default {
    name:'Footer',
    data: () => ({
      flag:true,
      links: [
        // {'name':'上传素材','path':'/swiper-upload-model','isMenu' :false},
        // {'name':'下载App','path':'/swiper-download-app','isMenu' :false},
        // {'name':'用户协议','path':'/swiper-user-agreement','isMenu' :true,'type':'user_agreement'},
        {'name':'隐私协议','path':'/swiper-privacy-agreement','isMenu' :true,'type':'privacy_agreement'},
        // /swiper-technical-support
        {'name':'技术支持网址(URL)','path':'/support','isMenu' :false},
      ],
      userAgreementList: [
        { 
          'title' : '时绽剪影立绘用户协议',
          'param':'user_policy_shizhan_jianyinglihui',
           'key' : Symbol()
        },
        { 
          'title' : '剪影立绘用户协议',
          'param':'user_policy_jianyinglihui',
           'key' : Symbol()
        },
        // { 
        //   'title' : '时绽用户协议',
        //   'param':'user_policy_shizhan',
        //    'key' : Symbol()
        // },
      ],
      privacyAgreementList: [
         { 
          'title' : 'Blessed Hero',
          'param':'private_policy_blessed_hero',
          'key' : Symbol()
        },
        // { 
        //   'title' : '时绽剪影立绘隐私协议',
        //   'param':'private_policy_shizhan_jianyinglihui',
        //    'key' : Symbol()
        // },
        // { 
        //   'title' : '剪影立绘隐私协议',
        //   'param':'private_policy_jianyinglihui',
        //    'key' : Symbol()
        // },
        // { 
        //   'title' : '时绽隐私协议',
        //   'param':'private_policy_shizhan',
        //    'key' : Symbol()
        // },
      ],
    }),
    created(){
      // console.log( this.$vuetify);
    },
   
    computed: {
       cols() {
          const {sm ,xs} = this.$vuetify.breakpoint;
           return sm ? true : xs ? true : false
        },
    },
     methods:{
      jumpHome(){
        this.$router.replace('/swiper')
      },
      toAgreementPage(param){
          this.$router.push({
           path:'/shizhan-agreement',
           query:{
             'param':param
           }
          });
        // https://blog.csdn.net/Fiona_AmyZhang/article/details/119639864
        // this.flag = !this.flag;
        //  // /privacy-agreement /user-agreement 都可以 为了刷新
        // if(this.flag){
        //   this.$router.push({
        //     path:'/swiper-user-agreement',
        //     query:{
        //      'param':param
        //    }
        //  });
        // }else{
        //   this.$router.push({
        //    path:'/swiper-privacy-agreement',
        //    query:{
        //      'param':param
        //    }
        //  });
        // }
       
      
      },
      toggleRouter(path) {
            this.$router.push(path)  // 这种可以后退 和以下的方法选一即可
            if(this.$router.path === path) return  // 无痕浏览
      }
     },

  }
</script>
<style lang="scss" scoped>
.element {
   width: 100%;
   height: 72px;
   box-sizing: border-box;
   padding-top: 16px;
   overflow-x: scroll; /* 设置溢出滚动 */
   white-space: nowrap;
   overflow-y: hidden;
   /* 隐藏滚动条 */
   scrollbar-width: none; /* firefox */
   -ms-overflow-style: none; /* IE 10+ */
}
.element::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
</style>